



















import { ref, onMounted, Ref, computed } from '@vue/composition-api';
import axios from 'axios';

export default {
  name: 'ConfirmDiscord',
  props: {
    code: {
      type: String,
      required: true
    }
  },
  setup(props, vm) {
    const color = ref('grey');
    const displayMessage = ref('..... Earth to Discord');
    const confirmationError = ref(false);
    const studentDoc: Ref<{
      data: Record<string, any>;
      update: (shouldMarkAsComplete?) => Promise<any>;
      changeStream: any;
    }> = ref({
      data: {},
      update: async () => null,
      changeStream: undefined
    });

    const programId: any = computed(() => {
      return localStorage.getItem('programId');
    });

    const adkIndexValue = computed(() => {
      return localStorage.getItem('adkIndexValue');
    });

    const userType = computed(() => {
      return localStorage.getItem('userType');
    });

    const userId: any = computed(() => {
      return localStorage.getItem('userId');
    });

    const studentId: any = computed(() => {
      return localStorage.getItem('studentId');
    });
    const redirectUrl: any = computed(() => {
      return localStorage.getItem('redirectUrl');
    });

    const clearLocalStorage = () => {
      localStorage.removeItem('programId');
      localStorage.removeItem('userType');
      localStorage.removeItem('adkIndexValue');
      localStorage.removeItem('userId');
      localStorage.removeItem('studentId');
      localStorage.removeItem('redirectUrl');
    };

    onMounted(async () => {
      try {
        const resp = await axios.post(
          `${process.env.VUE_APP_COWORK_SERVICE}/discord-info`,
          {
            user_type: userType.value,
            program_id: programId.value,
            public_id: studentId.value,
            code: props.code,
            redirect_uri: process.env.VUE_APP_DISCORD_REDIRECT
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );
        color.value = 'green';
        displayMessage.value = 'Your account has been linked, close this tab.';
        setTimeout(() => {
          window.location.href = redirectUrl?.value;
          clearLocalStorage();
        }, 1000);
      } catch (err) {
        console.log(err);
        confirmationError.value = true;
        color.value = 'red';
        displayMessage.value = 'Uh oh... We got discombobulated';
        setTimeout(() => {
          window.location.href = redirectUrl?.value;
          clearLocalStorage();
        }, 1000);
      }
    });

    return {
      confirmationError,
      displayMessage,
      studentDoc,
      color,
      programId
    };
  }
};
